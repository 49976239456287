import React from "react";
import AppLinks from "../AppLinks";
import { BsPersonCircle } from "react-icons/bs";
import cn from "classnames";
import { GiHamburgerMenu } from "react-icons/gi";
import { LanguageSelector } from "../../../Components/LanguageSelector";
import RestaurantsIcon from "../../../Assets/icon.png";
import styles from "./MobileVersion.module.css";
import { useNavigate } from "react-router-dom";

const MobileVersion = ({ t }) => {
  const navigate = useNavigate();
  const [isNavOpen, setIsNavOpen] = React.useState(false);

  const closeNav = () => {
    setIsNavOpen(false);
  };

  return (
    <div className={cn("flex flex-col", styles.mobile_nav)}>
      <div className="flex flex-row duration-300 transition-all z-10 w-full items-center px-5 justify-between py-2 relative">
        <button className="z-10" onClick={() => setIsNavOpen(!isNavOpen)}>
          <GiHamburgerMenu size={30} color="#00B27A" />
        </button>
        <div className="w-1/3 xsm:w-2/3 sm:w-2/3 mx-auto z-10">
          <img
            src={RestaurantsIcon}
            alt="logo"
            className="w-full h-full cursor-pointer"
            onClick={() => navigate("/")}
            style={{ maxWidth: 150, margin: "auto" }}
          />
        </div>
        <div className="flex flex-row">
          <BsPersonCircle
            onClick={() => {
              closeNav();
              navigate("/login");
            }}
            size={30}
            color="#00B27A"
            className="cursor-pointer"
          />
          <LanguageSelector />
        </div>
      </div>
      <nav>
        <div className={`MOBILE-MENU ${isNavOpen ? styles.showMenuNav : styles.hideMenuNav}`}>
          <div className="w-full flex flex-row items-center justify-evenly mt-10">
            <BsPersonCircle
              onClick={() => {
                closeNav();
                navigate("/login");
              }}
              size={30}
              color="#00B27A"
              className="cursor-pointer"
            />
            <div className="w-2/3">
              <img src={RestaurantsIcon} alt="logo" className="w-full h-full" />
            </div>
            <div onClick={closeNav}>
              <svg
                className="h-8 w-8 text-gray-600 cursor-pointer"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#00B27A"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </div>
          </div>
          <ul className="w-full md:w-[80%] mx-auto h-full items-left mt-24 flex flex-col">
            {AppLinks.map((link) => (
              <li
                className="cursor-pointer m-2 text-rcsecondarygray Brevia700 uppercase font-semibold text-2xl"
                onClick={() => {
                  closeNav();
                  if (link.externalUrl) {
                    window.open(link.link, "_blank");
                  } else {
                    navigate(link.link);
                  }
                }}
                key={link.title}
              >
                {t(link.title)}
              </li>
            ))}
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default MobileVersion;
