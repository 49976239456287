import React from "react";

function AccordoTitolarieta() {

    return (
        <div className="text-start px-32 py-12">
            Accordo Data Protection fra Titolari Autonomi<br></br>
            e contestuale nomina  a Responsabile esterno del Trattamento<br></br>

            TRA<br></br>
            AiGot S.r.l., con sede legale in Milano (MI) via Visconti di Modrone 38, cap 20122, e sede operativa in Pisa (PI) via Guglielmo Oberdan 14, cap 56127, CF/P.IVA 11498080966, indirizzo e-mail privacy@aigot.com, indirizzo PEC aigot@pec.com, telefono 0500985159 (di seguito “AiGot”)<br></br>
            <br></br>
            il Ristoratore così come individuato nel Contratto di Licenza d’uso del Software e servizi correlati e nel relativo Modulo d'ordine.<br></br><br></br>

            Premesse<br></br><br></br>
            a) AiGot e il Ristoratore hanno sottoscritto un Contratto di Licenza d’uso del Software denominato “Restaurants Club” e servizi correlati (d’ora in poi “Contratto”) sviluppato da AiGot per la creazione automatica di piattaforme che possono essere utilizzate dai soggetti che operano nel settore Ho.Re.Ca, per l’erogazione di servizi online e per l’acquisto di Campagne Marketing;<br></br><br></br>
            b) in particolare il Software consente la creazione, la gestione e l’aggiornamento di un sito web e di un’app per il Ristoratore e della pagina Google MyBusiness del Ristoratore, l'acquisto e la gestione di Campagne Marketing, nonché di usufruire di servizi che implicano anche il coinvolgimento di soggetti terzi, clienti o potenziali clienti del Ristoratore (denominati “Utenti”);<br></br><br></br>
            c) l’utilizzo del Software e dei servizi ad esso collegati può comportare, pertanto il trattamento di Dati Personali degli Utenti estranei al rapporto contrattuale in essere tra le Parti, motivo per cui si rende necessario disciplinare i rapporti tra le Parti in ordine al trattamento di tali dati.<br></br><br></br>

            1. Ambito di competenza<br></br><br></br>
            Le Parti si danno reciprocamente atto di conoscere ed applicare, nell’ambito delle proprie organizzazioni, tutte le norme vigenti ed in fase di emanazione in materia di trattamento dei Dati Personali, sia primarie che secondarie, rilevanti per la corretta gestione del trattamento, ivi compreso il Regolamento UE 2016/679 del Parlamento europeo e del Consiglio del 27 aprile 2016 (di seguito “GDPR”).
            Le Parti si danno reciprocamente atto che lo scambio di dati oggetto del presente Accordo risponde ai principi di liceità ed avviene in funzione dell’esecuzione del Contratto tra esse sottoscritto.
            Le Parti tratteranno in via autonoma i Dati Personali degli Utenti oggetto di condivisione, trasmissione e/o scambio, per le finalità connesse all’esecuzione del presente Accordo.<br></br><br></br>

            2.  Tipologia di dati trattati<br></br><br></br>
            I Titolari trattano le seguenti tipologie di Dati Personali forniti dagli Utenti:<br></br><br></br>
            - Dati di contatto: nome, cognome, indirizzo, e-mail, recapito telefonico, codice fiscale, credenziali di autenticazione al sito web e app.<br></br>
            - Dati fiscali e di pagamento: partita IVA, dati di acquisto, dati di pagamento.<br></br>
            - Dati tecnici: dati di navigazione, indirizzo IP, indicazione tipo di browser, indicazione provider internet, dati di localizzazione.<br></br>
            - Dati particolari di cui all’art. 9 GDPR relativi alla salute dell’Utente con particolare riferimento ad allergie e intolleranze alimentari.<br></br><br></br>

            3. Rapporti fra autonomi Titolari del trattamento dei dati<br></br><br></br>
            Le Parti tratteranno in via autonoma i Dati Personali degli Utenti oggetto di condivisione, trasmissione e/o scambio, per le finalità connesse all’esecuzione del Contratto tra esse sottoscritto.<br></br>
            Le Parti, in relazione agli impieghi dei predetti dati nell’ambito della propria organizzazione, assumeranno, pertanto, la qualifica di Titolare autonomo del trattamento ai sensi dell’articolo 4, nr. 7) del GDPR, sia fra di loro che nei confronti dei soggetti cui i dati personali trattati sono riferiti, ad eccezione dei casi previsti dall’art. 10 in cui AiGot tratterà tali dati in qualità di Responsabile esterno del trattamento.<br></br>
            Le Parti convengono che i Dati Personali presenti negli archivi, tanto cartacei quanto informatizzati, nonché quelli futuri, verranno trattati per le finalità di esecuzione degli obblighi contrattuali e precontrattuali assunti; per finalità di gestione dei rapporti con gli Utenti; per finalità di marketing e profilazione; per l’adempimento di obblighi previsti da leggi, da regolamenti e dalla normativa comunitaria, nonché da disposizioni impartite da Autorità a ciò legittimate dalla legge e da Organi di Vigilanza e Controllo; per finalità di tutela dei propri diritti in sede giudiziale e stragiudiziale innanzi a qualsiasi Autorità in ogni stato e grado di giudizio.<br></br><br></br>

            4. Rispetto della normativa e misure di sicurezza.<br></br><br></br>
            In quanto Titolari autonomi del trattamento, le Parti sono tenute a rispettare tutte le normative vigenti sulla protezione ed il trattamento dei Dati Personali.<br></br>
            Le Parti si impegnano a mettere in atto autonomamente tutte le misure di sicurezza previste dalle normative vigenti, in particolare le misure di sicurezza contro i rischi di distruzione, perdita, anche accidentale, di accesso o modifica non autorizzata dei dati o di trattamento non consentito o non conforme alle finalità della raccolta.<br></br>
            Parimenti, in via del tutto indipendente, ciascuna Parte si impegna a mettere in atto misure tecniche ed organizzative adeguate per garantire, ed essere in grado di dimostrare che il trattamento è effettuato conformemente al Regolamento.<br></br><br></br>

            5. Obblighi del personale autorizzato<br></br><br></br>
            Le Parti si impegnano a far sì che l’accesso ai Dati Personali oggetto dello scambio sia consentito soltanto a soggetti autorizzati e soltanto nella misura in cui ciò sia necessario per l’esecuzione del Contratto; le Parti si impegnano inoltre a fare in modo che l’uso dei Dati Personali da parte di tali soggetti sia conforme alla normativa vigente e venga posta in essere con modalità adeguate rispetto alla tipologia dei dati trattati e dei rischi connessi a tale trattamento.<br></br>
            Le Parti si impegnano a comunicare tempestivamente all’altra parte modifiche, aggiornamenti, esigenze, problematiche, incidenti e quanto ritenuto rilevante ai fini della corretta gestione dei dati.<br></br><br></br>

            6. Responsabilità<br></br><br></br>
            Fatto salvo quanto previsto da inderogabili norme di legge, ciascuna Parte assicura la regolarità dell’esecuzione del presente Accordo, ma resta esonerata da qualsiasi responsabilità contrattuale e/o extracontrattuale per danni diretti e/o indiretti (sia con riferimento al danno emergente, sia al lucro cessante) subiti da terzi in conseguenza dell’eventuale illiceità o non correttezza delle operazioni di trattamento imputabili esclusivamente all’altra Parte.<br></br><br></br>

            7. Impostazione organizzativa<br></br><br></br>
            Le parti garantiscono reciprocamente che i dati trattati da ciascuna di esse in esecuzione del presente Accordo formano oggetto di puntuale verifica di conformità alla disciplina rilevante in materia di trattamento di dati personali - ivi compreso il GDPR.<br></br>
            Le Parti si impegnano altresì a cooperare nel caso in cui una di esse risulti destinataria di istanze per l’esercizio dei diritti degli interessati previsti dall’articolo 12 e ss. del GDPR ovvero di richieste da parte delle Autorità di controllo che riguardino ambiti di trattamento di competenza dell’altra parte.<br></br><br></br>

            8. Durata<br></br><br></br>
            Il presente accordo rimarrà valido ed efficace sin tanto che rimarrà in vigore il Contratto sottoscritto tra le Parti.<br></br>
            L’Accordo sostituisce ogni precedente accordo tra le Parti avente il medesimo oggetto.<br></br><br></br>

            9. Legge applicabile e Foro competente<br></br><br></br>
            Il presente Accordo è regolato dalla legge italiana e le Parti concordano che per ogni controversia relativa all’interpretazione o esecuzione dell’Accordo o, comunque, ad esso connessa, sarà competente in via esclusiva il Foro di Pisa.<br></br><br></br>

            10. Nomina di AiGot quale Responsabile del Trattamento.<br></br><br></br>
            Fatto salvo quanto previsto dagli articoli precedenti, il Ristoratore, per le sotto indicate finalità, affida ad AiGot, quale Responsabile, le seguenti attività di trattamento dei Dati Personali di cui all’art. 2: consultazione, raccolta, registrazione, organizzazione, strutturazione, conservazione, modifica, estrazione, elaborazione, comunicazione, limitazione, cancellazione.<br></br>
            Le predette operazioni sono affidate ad AiGot quale Responsabile del Trattamento, quando questa sia chiamata ad operare per le seguenti finalità nell’interesse del Ristoratore<br></br>
            - identificazione e risoluzione di  eventuali  problemi  tecnici del Software, della Piattaforma, del sito web, dell’app e dei servizi da questo creati;<br></br>
            - assistenza nella gestione,  ottimizzazione  e  monitoraggio  dell’infrastruttura  tecnica del sito web e dell’app del Ristoratore;<br></br>
            - assistenza nella gestione, ottimizzazione e monitoraggio dell’infrastruttura tecnica sottesa ai servizi offerti agli Utenti per il tramite del Software;<br></br>
            - assistenza nella gestione e organizzazione delle informazioni del sistema informatico (ad es. server, database etc.)<br></br>
            - miglioramento delle  performance  del sistema informatico, del Software e dei i servizi ad esso correlati.<br></br><br></br>
            AiGot in qualità di Responsabile, ai sensi dell’articolo 28 paragrafo 3, lett. b), garantisce che tutte le persone interne incaricate del trattamento sono espressamente autorizzate con impegno di riservatezza in merito alle informazioni di cui vengono a conoscenza.<br></br><br></br>
            AiGot, in qualità Responsabile, si impegna inoltre a:<br></br><br></br>
            - osservare le norme di cui al Reg. UE 679/2016 ed al d.lgs. 30 giugno 2003, n. 196 e le altre disposizioni legislative e regolamentari in materia di riservatezza dei dati personali osservando i principi di liceità e correttezza;<br></br>
            - supervisionare le operazioni di trattamento dei dati personali effettuate all'interno delle aree di sua competenza;<br></br>
            - assistere il Titolare del Trattamento nel garantire i diritti riconosciuti all'interessato dal GDPR e dal Codice della Privacy;<br></br>
            - osservare le disposizioni che verranno impartite per iscritto dal Titolare del Trattamento;<br></br>
            - trattare i dati personali su istruzione documentata del Titolare del Trattamento anche in caso di trasferimento di dati personali verso un paese terzo o un'organizzazione internazionale, salvo che lo richieda il diritto dell'Unione o nazionale cui è soggetto il Responsabile del Trattamento. In tale ultimo caso, il Responsabile dovrà informare il Titolare del Trattamento circa tale obbligo giuridico prima del trattamento, con le eccezioni previste dall'art. 28, comma 3, lett. a) del Regolamento 679 /2016;<br></br>
            - assistere il Titolare del Trattamento nel rispettare tutti gli obblighi normativi sulla protezione dei dati personali e sui limiti del trattamento come risultano dalle disposizioni di cui al Regolamento UE 679 /2016, Capi III e rv; nonché delle rilevanti norme del Codice della Privacy e qualsiasi altra normativa vigente in materia sulle quali gli stessi Responsabili del Trattamento sono stati resi edotti mediante le attività di formazione;<br></br>
            - attuare gli obblighi di informativa e di acquisizione del consenso nei confronti degli interessati nel rispetto della normativa vigente per il trattamento dei dati di cui è responsabile.<br></br><br></br>
            Il Responsabile può procedere alla nomina di sub-responsabili per l’espletamento dell’incarico ricevuto a condizione che informi il Titolare del trattamento ai sensi dell’articolo 28, paragrafo 2, del Regolamento (Ue) 2016/679.<br></br><br></br>
            Le Parti danno atto che le obbligazioni di cui al presente articolo a carico di AiGot sono riferibili unicamente ai sistemi informativi, ai sistemi di archiviazione ed ai dati personali per i quali AiGot effettui un qualsiasi trattamento per conto del Ristoratore, ricevendo da questo indicazioni o istruzioni in merito alle finalità e alle modalità di trattamento e di protezione.<br></br><br></br>
            La nomina non si applica ai sistemi informativi ed archivi di dati per i quali AiGot non disponga di credenziali di accesso o amministrazione, nonché per sistemi fisicamente localizzati presso i locali del Ristoratore e da questi interamente gestiti.<br></br><br></br>



            <div className="text-start">
                Luogo e Data ______________________________<br></br><br></br>


                AiGot S.r.l.
                (anche per accettazione della nomina di cui all’art. 10)      __________________________________________<br></br><br></br>



                Firma Ristoratore     					     __________________________________________<br></br><br></br>
                (anche per accettazione espressa dell’art 9)
            </div>
        </div>
    );
};

export default AccordoTitolarieta;