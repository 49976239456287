import Products from "./Products";
import Resources from "./Resources";

const AppLinks = [

  {
    title: "nav.functions",
    link: "/funzioni",
  },

  {/* {
    title: "nav.product.title",
    link: "/funzioni",
    shouldExpand: true,
    children: <Products />,
    mobileArray: [
      {
        title: "nav.product.comparePlans.title",
        link: "/funzioni",
      },
     {
        title: "nav.product.espressoPackage.title",
        link: "/espresso",
      },
      {
        title: "nav.product.wayfarerPackage.title",
        link: "/viandante",
      },
      {
        title: "nav.product.packageAtTheTable.title",
        link: "/tavola",
      },
    ],
  },*/},


  {
    title: "nav.prices",
    link: "/prezzi",
  },


  {/*  {
    title: "nav.resources.title",
    shouldExpand: true,
    children: <Resources />,
    mobileArray: [
      {
        link: "/faq",
        title: "FAQs",
      },
      {
        link: "https://wa.me/+393312910408",
        title: "nav.resources.whatsapp",
        externalUrl: true,
      },
      {
        link: "tel:0500985159",
        title: "nav.resources.telephone",
        externalUrl: true,
      },
    ],
  },*/},
  {
    title: "nav.contacts",
    link: "/Contatti",
  },
];
export default AppLinks;
